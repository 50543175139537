<template>
  <div class="reset-email">
    <div class="reset-email-left">
      <div class="reset-email-left-inner">
        <div class="reset-email-left-inner-logo">
          <img src="/img/smaaart-logo.svg" alt="logo" />
        </div>
        <div class="reset-email-left-inner-text">
          <h1>{{ $t("COMMON.RESET_PASSWORD") }}</h1>
        </div>
        <div class="reset-email-left-inner-form">
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit()"
            v-if="!passwordChanged"
          >
            <base-alert :dismissible="true" type="danger" v-if="errors.token">
              {{ $t("COMMON.TOKEN_ERROR") }}
            </base-alert>

            <base-alert :dismissible="true" type="danger" v-if="errors.email">
              {{ $t("COMMON.EMAIL_ERROR") }}
            </base-alert>

            <base-alert type="success" v-if="passwordChanged">
              {{ $t("COMMON.PASSWORD_UPDATED") }}
              <router-link :to="{ name: 'Login' }" class="text-light">
                <small>{{ $t("COMMON.RETURN_TO_LOGIN") }}</small>
              </router-link>
            </base-alert>

            <base-input
              alternative
              class="mb-3"
              prepend-icon="ni ni-lock-circle-open"
              :placeholder="$t('COMMON.PASSWORD')"
              type="password"
              name="Password"
              v-model="form.data.attributes.password"
            >
            </base-input>
            <validation-error :errors="errors.password" />

            <base-input
              alternative
              class="mb-3"
              prepend-icon="ni ni-lock-circle-open"
              :placeholder="$t('COMMON.CONFIRM_PASSWORD')"
              type="password"
              name="Password confirmation"
              v-model="form.data.attributes.password_confirmation"
            >
            </base-input>
            <validation-error :errors="errors.password_confirmation" />

            <div class="text-center">
              <base-button type="primary" native-type="submit" class="my-4">
                {{ $t("COMMON.RESET_PASSWORD") }}
              </base-button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="reset-email-right">
      <img src="/img/login-image.webp" alt="login" />
      <lang-switcher />
    </div>
  </div>
</template>
<script>
import { BaseAlert } from "@/components";
import ValidationError from "@/components/ValidationError.vue";

export default {
  layout: "AuthLayout",
  mixins: [],
  components: { ValidationError, BaseAlert },
  data() {
    return {
      form: {
        data: {
          type: "password-reset",
          attributes: {
            password: "",
            password_confirmation: "",
            token: "",
            email: "",
          },
        },
      },
      passwordChanged: false,
      errors: {},
    };
  },
  mounted() {
    this.form.data.attributes.email = this.$route.query.email;
    this.form.data.attributes.token = this.$route.query.token;
  },
  beforeDestroy() {
    this.$router.replace({ query: null });
  },
  methods: {
    async handleSubmit() {
      this.errors = {};
      try {
        await this.$store.dispatch("reset/createNewPassword", this.form.data);
        this.passwordChanged = true;
      } catch (error) {
        this.errors = error.response.data.errors;
      }
    },
  },
};
</script>